export function setupLanguageSwitcher(currentLang) {
    const currentPath = location.pathname;
    $('.language-list__item').each((index, element) => {
        const _el = $(element);
        _el.prop("href", currentPath.replace(('/' + currentLang + '/'), ('/' + _el.data("lang") + '/'))).removeClass('language-list__item--active');

        if (_el.data("lang") === currentLang) {
            $(".language-list__name").html(_el.text());
            _el.addClass('language-list__item--active');
        }
    });
}

